<template>
 <div>
    <v-row no-gutters class="pa-1">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else class="pa-2">
            <div class="text-center mb-5">
                 <span class="lg-font text-primary">  {{$money(account.comm_amount)}}</span> <br>Current Commission
            </div>
                <v-toolbar dense flat class="pa-0 mb-4">
                 
                     <span style="width: 170px;"> 
                      <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="From Date"
                              :value="from_date"
                              readonly
                              filled
                              dense
                              hide-details
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
                     </span>
                      <v-spacer/>
                     <span style="width: 170px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="To Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              hide-details
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, get_commission()" />
                    </v-menu>
                     </span>
                </v-toolbar>
                <div id="PrintReport">
                  <table class="print_table md-font" >
                      <tr>
                          <th class="text-left">From</th>
                          <th class="text-left">DT</th>
                          <th class="text-right">COMM</th>
                      </tr>
                      <tr v-for="(item, index) in transactions" :key="index">
                          <td :class="'td-'+IS_ODD(index)">{{item.from_buyer==user.username?"You":item.from_buyer}}</td>
                            <td :class="'td-'+IS_ODD(index)">{{$moment(item.created_at).format("MMM DD, YYYY hh:mm a")}} </td>
                          <td class="text-right" :class="'td-'+IS_ODD(index)">{{$money(item.comm_amount)}}</td>
                      </tr>
                       <tr>
                          <th class="text-right" colspan="2">Date Covered {{$moment(from_date).format("MMM, DD, YYYY")}} To  {{$moment(to_date).format("MMM, DD, YYYY")}}</th>
                           <th class="lg-font text-right" colspan="2">{{$money(commission)}}</th>
                      </tr>
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
       from_menu: false,
        search:'',
        from_date:'',
        account: {},
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
       this.CHECK_BALANCE(this.user.id)
       this.from_date =  this.$moment().subtract(7, "days").format("YYYY-MM-DD")
        this.to_date =  this.$moment().format("YYYY-MM-DD")
        this.get_commission()
        this.userDetails()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      balance(){
        return  this.$store.state.balance.balance
      },
      total(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Buy Ticket") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
       commission(){
        var total = this.transactions.reduce((res,item)=>{
             res+=this.$IsNum(item.comm_amount)
            return res
        }, 0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      get_commission(){
        this.transactions=[]
          this.$http.post("commission/query", {from_date: this.from_date, to_date:this.to_date}).then(response => {
           response.data.logs != null?this.transactions =  response.data.logs:this.transactions=[]
           console.log(response.data.logs)
         }).catch(e => {
           console.log(e.data)
         })
      },
      userDetails(){
       console.log(this.user)
          this.$http.post("user/details",{id: this.user.id}).then(response => {
            console.log(response.data.account)
              if(response.data.status) {
                 this.account = response.data.account
              } 
          }).catch(e => {
            console.log(e.data)
          })
    },
    },
  }
</script>